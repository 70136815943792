/** @jsx jsx */
import {
  jsx,
  Grid,
  Flex,
  Box,
  Styled,
  Button,
  Container,
  Link as ThemeLink,
} from "theme-ui"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

const Text = props => (
  <Styled.p
    sx={{
      fontSize: [2, 3],
    }}
  >
    {props.children}
  </Styled.p>
)

const MaijuPage = ({ data }) => (
  <Layout>
    <SEO title="Maiju" />
    <Container sx={{ mt: [128, null, 256], maxWidth: "80ch", px: 3 }}>
      <Styled.h1 sx={{ textAlign: "center" }}>Maiju</Styled.h1>
      <Box sx={{ mb: [4], maxWidth: 400, mx: "auto" }}>
        <Img
          fluid={{ ...data.maiju.childImageSharp.fluid, aspectRatio: 1 }}
          alt="Maiju Roine"
          sx={{
            borderRadius: "50%",
          }}
          imgStyle={{ objectPosition: "top left" }}
        />
      </Box>
      <Text>
        Olen Maiju Roine, rovaniemeläinen pianisti ja Lapin musiikkiopistossa
        työskentelevä pianonsoiton lehtori. Olen lähtöisin Suomussalmelta, missä
        opiskelin pianonsoittoa Kainuun musiikkiopiston oppilaana. Jatkoin
        pianonsoiton ammattiopintoihin Tampereelle, mistä valmistuin Kristiina
        Kask-Valven pianoluokalta musiikkipedagogiksi (AMK) vuonna 2007.
        Seuraavana syksynä aloitin lukuvuoden mittaisen viransijaisuuden Lapin
        musiikkiopistossa - luonnonläheinen kulttuurikaupunki Rovaniemi vei
        sydämeni, joten pestin päätyttyä päätin palata vielä joskus takaisin,
        jos vain mahdollista. Jatkoin opintojani Tampereella vielä vuosina
        2008-2010, jolloin tein pianonsoiton A-kurssin resitaalin ja konserton
        erinomaisin arvosanoin. Työskentelin pianonsoiton opettajana Porissa
        Palmgren-konservatoriossa (2009-2012), Tampereen konservatoriossa
        (lukuvuosina 2013-2014 ja 2014-2015) ja muun muassa Valkeakosken
        musiikkiopistossa (2014-2015). Veri veti kuitenkin takaisin pohjoiseen,
        joten sopivan viran avauduttua muutin perheineni Rovaniemelle ja aloitin
        työt Lapin musiikkiopistossa syksyllä 2015.
      </Text>
      <Text>
        Työn ohessa, vuosien varrella olen säveltänyt oppilaille lukuisia
        pianokappaleita, ja syksyllä 2018 sävelsin ensimmäisen isomman
        kokoelmani pianominiatyyrejä. Kahdeksan vuodenaikaa ja muita
        pianokappaleita julkaistiin alkuvuonna 2019 ja esiteltiin heti myös
        Pianopedagogit ry:n vuosiseminaarissa. Vastaanotto oli myönteinen ja
        kannusti säveltämään lisää! Fantasiakappaleita lapsille julkaistiin
        alkuvuonna 2020. Tällä hetkellä työn alla on kolmas kokoelma. Opettajana
        olen luova, monipuolinen ja innostava ja tykkään järjestää myös
        konsertteja ja tapahtumia. Toimin myös Lapin pianoseura ry:ssä, jonka
        tarkoitus on edistää pianonsoiton esittämistä ja opetusta sekä
        yhteistyötä Lapissa ja lähialueilla. Olen osallistunut aktiivisesti
        oppilaitteni kanssa sekä valtakunnallisiin että paikallisiin tapahtumiin
        ja esiinnyn pianistina säännöllisesti mm. Lapin musiikkiopiston
        konserttisarjoissa. Yhteistyö laulajien kanssa on minulle läheistä, ja
        toiminkin tällä hetkellä Lapin musiikkiopistossa laulajien säestäjänä.
        Olen myös vapaan säestyksen opettaja ja pidän monenlaisesta hyvästä
        musiikista.
      </Text>
      <Text>
        Valmistuin musiikkipedagogiksi (YAMK) Oulun ammattikorkeakoulusta
        Taiteen tekijä ja kehittäjä -koulutusohjelmasta vuonna 2020 ja tutkin
        opinnäytetyössäni sävellyttämisen mahdollisuuksia. Säveltäminen ja
        sävellyttäminen kiinnostavat minua, ja opetuksessani hyödynnän luovia
        musiikin tekemisen muotoja monipuolisesti. Säveltämiseen minua on
        perehdyttänyt Jouko Tötterström, jolta olen saanut hyviä vinkkejä ja
        kannustusta. Säveltäessäni ajattelen oppilaan näkökulmaa: oppilaalle
        sopiva sävellys on riittävän helppo, mutta antaa mahdollisuuksia
        oppimiseen; se tuottaa musiikillisen elämyksen ja istuu nuoren soittajan
        käteen, innostaa harjoittelemaan ja koukuttaa.
      </Text>
      <Text>
        Lasten ja nuorten taidekasvatus on minulle tärkeää, ja haluan olla
        vaikuttamassa siihen, että yhä uudet sukupolvet pääsevät nauttimaan
        soittotaidosta ja korkeatasoisesta musisoinnista. Nautin työstäni lasten
        ja nuorten opettajana, ja siitä, että lapset ja nuoret saavat
        mahdollisuuden taiteen tekemiseen ja kokemiseen. Pidän yhtä tärkeinä
        pianonsoiton taidetta ja opettamisen taidetta.
      </Text>
      <Styled.h2 sx={{ mt: [4, 5] }}>Linkkejä</Styled.h2>
      <Box as="ul" sx={{ listStyle: "none", fontSize: [2, 3], p: 0, m: 0 }}>
        <li>
          <Styled.a href="https://www.lapinkansa.fi/pianonsoitonopettaja-maiju-roine-keksi-tavan-autta/156631">
            Lapin kansan henkilökuva
          </Styled.a>
        </li>
        <li>
          <Styled.a href="https://www.oamk.fi/epooki/2020/taidetta-ja-elamaa/">
            Julkaistu artikkeli OAMK:in tutkimus- ja kehitystyön julkaisussa
          </Styled.a>
        </li>
        <li>
          <Styled.a href="https://www.theseus.fi/bitstream/handle/10024/352416/Roine_Maiju.pdf?sequence=2&isAllowed=y">
            Musiikkipedagogi YAMK opinnäytetyöni “Soittotaitoa sävellyttäen”
          </Styled.a>
        </li>
        <li>
          <Styled.a href="https://www.lapinpianoseura.fi/">
            Lapin pianoseura
          </Styled.a>
        </li>
        <li>
          <Styled.a href="https://www.rovaniemi.fi/musiikkiopisto">
            Lapin musiikkiopisto
          </Styled.a>
        </li>
      </Box>
    </Container>
  </Layout>
)

export default MaijuPage

export const query = graphql`
  query {
    maiju: file(relativePath: { eq: "maiju.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
